<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Campings</v-card-title>

      <v-row class="mb-5">
        <v-col
          class="tags-search ml-5"
          cols="2"
          sm="2"
        >
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            placeholder="Recherche"
            @tags-changed="newTags => {
              tags = newTags
              search()
            }"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            class="mt-1"
            small
            color="info"
            @click="search()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiDatabaseSearchOutline }}
            </v-icon>
            <i class="fas fa-search"></i>
            Rechercher
          </v-btn>
          <v-btn
            small
            color="info"
            class="ml-2 mt-1"
            @click="reset()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiBackupRestore }}
            </v-icon>
            Réinitialisation
          </v-btn>
        </v-col>
      </v-row>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Nom
              </th>
              <th class="text-center text-uppercase">
                Poids
              </th>
              <th class="text-center text-uppercase">
                ID Secure Holiday
              </th>
              <th class="text-center text-uppercase">
                ID Flower Data
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(camping, campingIndex) in campings"
              :key="'c'+ campingIndex"
            >
              <td class="font-weight-bold">
                {{ camping.name }}
              </td>
              <td class="text-center">
                {{ camping.weight }}
              </td>
              <td class="text-center">
                {{ camping.secureHolidayId }}
              </td>
              <td class="text-center">
                {{ camping.flowerDataId }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'campingDetails', params: { campingId: camping.id }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiPen }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPen, mdiBackupRestore, mdiDatabaseSearchOutline } from '@mdi/js'
import Vue from 'vue'
import config from '../../config'
import VueTagsInput from '@johmun/vue-tags-input'
import Loader from '@/components/Common/Loader'

export default {
  components: { VueTagsInput, Loader },
  data: () => ({
    loading: false,
    campings: [],
    nextPage: 1,
    tag: '',
    tags: [],
    icons: {
      mdiPen,
      mdiBackupRestore,
      mdiDatabaseSearchOutline
    }
  }),
  computed: {
    isHandlingCampingScroll () {
      return this.$store.getters.isHandlingCampingScroll
    }
  },
  created () {
    this.getCampings()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingCampingScroll) {
        this.getCampings()
      }
    },
    getCampings () {
      this.loading = true

      const params = {
        page: this.nextPage
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingCampingScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/campings', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const campings = response.data

            if (campings && campings.length) {
              this.campings.push(...campings)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingCampingScroll', false)
        })
        .catch(() => {
          this.loading = false
          this.$store.commit('setHandlingCampingScroll', false)
        })
    },
    search () {
      const params = {
        page: 1
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/campings', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            this.campings = response.data

            this.nextPage = 2
          }
        })
        .catch(() => {
        })
    },
    reset () {
      this.tag = ''
      this.tags = []

      this.search()
    }
  }
}
</script>

<style lang="scss">
  .global-actions {
    margin-bottom: 1rem;
  }
  .action {
    margin-right: 0.5rem;
  }
  .v-chip-light-bg {
    margin-right: 0.5rem;
  }
  .clear {
    clear: both;
  }
</style>
